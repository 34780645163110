<template>
    <div class="card-container" v-if="!done">
        <div class="card">
            <form @submit.prevent="handleSubmit">
                <h2>Adaugă un token</h2>
                <div class="form-group">
                    <label for="name">Denumire</label>
                    <input type="text" id="name" v-model="name" placeholder="Introduceți o denumire pentru acest token" :class="{ inactive: isLoading }"/>
                </div>
                <div class="form-group">
                    <label for="access_token">Token Acces</label>
                    <input type="text" id="access_token" v-model="accessToken" disabled class="inactive"/>
                </div>
                <div class="form-group">
                    <label for="refresh_token">Token Refresh</label>
                    <input type="text" id="refresh_token" v-model="refreshToken" disabled class="inactive"/>
                </div>
                <div class="form-group">
                    <span v-if="error" style="color: red">{{errorMessage}}</span>
                </div>
                <button type="submit" class="submit-button" :class="{ inactive: isNullOrWhitespace(name) || isLoading}">
                    <i class="dx-icon-plus"></i>
                    Add
                </button>
            </form>
        </div>
    </div>
    <div class="card-container" v-if="done">
        <div class="card">
            <h2>Token-ul a fost initializat cu succes!</h2>
            <img src="../assets/checkmark.png" style="width: 100px; height: 100px"/>
            <h2>Închideți această pagină, reveniți la ecranul de selecție și reîmprospătați lista de token-i!</h2>
        </div>
    </div>

</template>

<script>
// eslint-disable-next-line no-unused-vars
import config from "../configuration";

// eslint-disable-next-line no-unused-vars
import axios from "axios";

export default {
    name: "AddCertificateComponent",
    mounted() {
        this.token = localStorage.getItem("Token");
        this.accessToken = this.$route.query.access_token;
        this.refreshToken = this.$route.query.refresh_token;
    },
    data() {
        return {
            token: null,
            accessToken: "",
            refreshToken: "",
            name: "",
            error: false,
            errorMessage: "",
            done: false,
            isLoading: false
        }
    },
    methods: {
        isNullOrWhitespace(input) {
            return !input || !input.trim();
        },
        async handleSubmit() {
            try {
                this.isLoading = true;
                await axios.post(`${config.API_URL}/eFactura/SaveCertificateConfiguration`,
                    {
                        access_token: this.accessToken,
                        refresh_token: this.refreshToken,
                        Name: this.name
                    },
                    {
                        headers : {
                            'Authorization': `Bearer ${this.token}`
                        }
                    });
                this.done = true;
            }
            catch (error) {

                if (error?.response?.status === 401) {
                    alert("Autentificarea a expirat!");
                    this.$router.push('/Login');
                } else {
                    this.errorMessage = error?.response?.data;
                    this.error = true;
                }
            } finally {
                this.isLoading = false;
            }
        }
    }
}
</script>

<style scoped>
.card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.card {
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    padding: 30px; /* Increased padding to create more space inside the card */
    width: 500px;
    text-align: center;
}

.form-group {
    margin-bottom: 20px; /* Increased margin to create more space between form elements */
    text-align: left;
}

label {
    display: block;
    margin-bottom: 8px; /* Increased margin between label and input field */
    font-weight: bold;
}

input[type="text"],
input[type="password"] {
    width: 100%;
    padding: 12px; /* Increased padding inside input fields */
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

input[type="text"]:focus,
input[type="password"]:focus {
    border-color: #007BFF;
    outline: none;
}

.submit-button {
    width: 100%;
    padding: 12px; /* Increased padding inside the button */
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
}

.submit-button:hover {
    background-color: #0056b3;
}

.inactive {
    opacity: 0.5; /* Makes it look inactive */
    pointer-events: none; /* Disables all interactions */
}
</style>