import { createRouter, createWebHistory } from 'vue-router';
import LoginComponent from '../components/LoginComponent.vue';
import TokenAndFirmSelectionComponent from "@/components/TokenAndFirmSelectionComponent";
import PlaceholderComponent from "@/components/PlaceholderComponent";
import AddCertificateComponent from "@/components/AddCertificateComponent";
import DashboardComponent from "@/components/DashboardComponent";

const routes = [
    {
        path: '/Login',
        name: 'Login',
        component: LoginComponent,
    },
    {
        path: "/SelectTokenAndFirm",
        name: "SelectTokenAndFirm",
        component: TokenAndFirmSelectionComponent
    },
    {
        path: "/Placeholder",
        name: "Placeholder",
        component: PlaceholderComponent
    },
    {
        path: "/AddCertificate",
        name: "AddCertificate",
        component: AddCertificateComponent
    },
    {
        path: "/",
        name: "Dashboard",
        component: DashboardComponent
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
