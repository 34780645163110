<template>
    <NavbarComponent/>
    <div class="cards-container main-content">
        <div class="card_c">
            <h1>Configurare</h1>
            <p style="font-size: 16px">Alegeți firma ale cărei facturi doriți să le gestionați, și token-ul care vă va permite să faceți acest lucru.</p>
            <p style="font-size: 16px">Fără a face acest pas de configurare, nu vi se va permite accesul la nici o funcționalitate.</p>
            <div class="card-footer">

                <button type="submit" class="next-button" @click="subimtConfig()"
                    :class="{ inactive: isLoading || this.selectedTokens.length === 0 || this.selectedFirms.length === 0 }">
                    Salvează și mergi către dashboard
                    <i class="dx-icon-arrowright"></i>
                </button>
            </div>
        </div>
        <div class="card_c">
            <h1>Firmă</h1>
            <DxList
                v-model:selected-items="selectedFirms"
                :data-source="firms"
                :height="400"
                :search-enabled="true"
                selection-mode="single"
                search-mode="contains"
                search-expr="Name"
                :search-editor-options="{ placeholder: 'Caută' }"
                :select-by-click="false"
                :show-selection-controls="true"
            >
                <template #item="{ data }">
                    <div>
                        <div>{{ data.name + ' - ' + data.cif}}</div>
                    </div>
                </template>
            </DxList>
            <div class="card-footer_c">
                <button class="footer-button-refresh" @click="refreshFirms()" :class="{ inactive: isLoading }">
                    <i class="dx-icon-refresh"></i>
                    Reîncarcă firme
                </button>
                <button class="footer-button-add" @click="addFirm()" :class="{ inactive: isLoading }">
                    <i class="dx-icon-plus"></i>
                    Adaugă firmă
                </button>
            </div>
        </div>
        <div class="card_c">
            <h1>Token</h1>
            <DxList
                v-model:selected-items="selectedTokens"
                :data-source="tokens"
                :height="400"
                :search-enabled="true"
                selection-mode="single"
                search-mode="contains"
                search-expr="Name"
                :search-editor-options="{ placeholder: 'Caută' }"
                :select-by-click="false"
                :show-selection-controls="true"
            >
                <template #item="{ data }">
                    <div>
                        <div>{{ data.name }}</div>
                    </div>
                </template>
            </DxList>
            <div class="card-footer_c">
                <button class="footer-button-refresh" @click="refreshTokens()" :class="{ inactive: isLoading }">
                    <i class="dx-icon-lock"></i>
                    Reîncarcă token-i
                </button>
                <button class="footer-button-add" @click="addToken()" :class="{ inactive: isLoading }">
                    <i class="dx-icon-plus"></i>
                    Adaugă token
                </button>
            </div>
        </div>


    </div>
    <DxPopup
        v-model:visible="popupVisible"
        :drag-enabled="false"
        :hide-on-outside-click="true"
        :show-close-button="false"
        :show-title="true"
        :width="300"
        :height="390"
        container=".dx-viewport"
        title="Adaugă o firmă"
        @hidden="closePopup()"
    >
        <form @submit.prevent="submitFirm()">
            <div class="form-group">
                <label for="username">Denumire</label>
                <input type="text" id="username" v-model="name" placeholder="Introduceți denumirea firmei" :class="{ inactive: isLoading }"/>
            </div>
            <div class="form-group">
                <label for="password">CIF</label>
                <input type="text" id="password" v-model="cif" placeholder="Introduceți CIF-ul firmei" :class="{ inactive: isLoading }"/>
            </div>
            <div class="form-group">
                <span v-if="error" style="color: red">{{this.errorMessage}}</span>
            </div>
            <button type="submit" class="submit-button" :class="{ inactive: isNullOrWhitespace(name) || isNullOrWhitespace(cif) || isLoading}">
                <i class="dx-icon-plus"></i>
                Adaugă
            </button>
        </form>
    </DxPopup>
</template>

<script>
import DxList from 'devextreme-vue/list';
import axios from "axios";
import config from "@/configuration";
import { DxPopup } from 'devextreme-vue/popup';
import NavbarComponent from "@/components/NavbarComponent";

export default {
    name: "TokenAndFirmSelectionComponent",
    components: {
        NavbarComponent,
        DxList,
        DxPopup
    },
    data() {
        return {
            firms: [],
            tokens: [],
            token: null,
            popupVisible: false,
            error: false,
            name: '',
            cif: '',
            errorMessage: '',
            selectedFirms: [],
            selectedTokens: [],
            isLoading: false,
        }
    },
    async mounted() {
        this.token = localStorage.getItem("Token");

        try {
            let response = await axios.get(`${config.API_URL}/Data/GetTokens`,
                {
                    headers : {
                        'Authorization': `Bearer ${this.token}`
                    }
                });
            this.tokens = response.data;
        }
        catch (error) {
            alert("Error on loading data!");
        }

        try {
            let response = await axios.get(`${config.API_URL}/Data/GetFirms`,
                {
                    headers : {
                        'Authorization': `Bearer ${this.token}`
                    }
                });
            this.firms = response.data;
        }
        catch (error) {
            alert("Error on loading data!");
        }
        if (localStorage.getItem("Firm") !== null) {
            let firmSelected = JSON.parse(localStorage.getItem("Firm"));
            for (let i in this.firms) {
                if (this.firms[i].id === firmSelected.id) {
                    this.selectedFirms = [ this.firms[i] ];
                    break;
                }
            }

        }


        if (localStorage.getItem("Cert") !== null) {
            let certSelected = JSON.parse(localStorage.getItem("Cert"));
            for (let i in this.tokens) {
                if (this.tokens[i].id === certSelected.id) {
                    this.selectedTokens = [ this.tokens[i] ];
                    console.log(this.selectedTokens);
                    break;
                }
            }

        }
    },

    methods: {
        isNullOrWhitespace(input) {
            return !input || !input.trim();
        },
        isNumber(input) {
            return /^\d+$/.test(input);
        },
        async refreshTokens() {
            try {
                this.isLoading = true;
                let response = await axios.get(`${config.API_URL}/Data/GetTokens`,
                    {
                        headers : {
                            'Authorization': `Bearer ${this.token}`
                        }
                    });
                this.tokens = response.data;
            }
            catch (error) {
                if (error?.response?.status === 401) {
                    alert("Autentificarea a expirat!");
                    this.$router.push('/Login');
                } else {
                    alert("Eroare la încărcarea datelor!");
                }
            } finally {
                this.isLoading = false;
            }
        },
        async refreshFirms() {
            try {
                this.isLoading = true;
                let response = await axios.get(`${config.API_URL}/Data/GetFirms`,
                    {
                        headers : {
                            'Authorization': `Bearer ${this.token}`
                        }
                    });
                this.firms = response.data;
            }
            catch (error) {
                if (error?.response?.status === 401) {
                    alert("Autentificarea a expirat!");
                    this.$router.push('/Login');
                } else {
                    alert("Eroare la încărcarea datelor!");
                }
            } finally {
                this.isLoading = false;
            }
        },
        addFirm() {
            this.popupVisible = true;
        },
        addToken() {
            let url = `${config.API_URL}/eFactura/InitializeCertificate`;
            window.open(url, '_blank');
        },
        async submitFirm() {
            if (!this.isNumber(this.cif)) {
                this.errorMessage = 'CIF-ul poate conține doar cifre!';
                this.error = true;
                return;
            }
            try {
                this.isLoading = true;
                await axios.post(`${config.API_URL}/Data/AddFirm`,
                    {
                      Name: this.name,
                      CIF: this.cif
                    },
                    {
                        headers : {
                            'Authorization': `Bearer ${this.token}`
                        }
                    });
                this.popupVisible = false;
                this.error = false;
                this.errorMessage = '';
                this.name = '';
                this.cif = '';
                await this.refreshFirms();
            }
            catch (error) {
                if (error?.response?.status === 401) {
                    alert("Autentificarea a expirat!");
                    this.$router.push('/Login');
                } else {
                    this.errorMessage = error?.response?.data;
                    this.error = true;
                }

            } finally {
                this.isLoading = false;
            }

        },
        async closePopup() {
            this.error = false;
            this.errorMessage = '';
            this.name = '';
            this.cif = '';
            await this.refreshFirms();
        },
        subimtConfig() {
            localStorage.setItem("Firm", JSON.stringify(this.selectedFirms[0]));
            localStorage.setItem("Cert", JSON.stringify(this.selectedTokens[0]));
            this.$router.push("/");
        }

    }
}
</script>

<style scoped>
.cards-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px; /* Adds space between the two cards */
    height: 80vh;
}

.card_c {
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    padding: 30px;
    max-width: 400px;
    text-align: left; /* Align text to the left */
    flex: 1; /* Allows the cards to be responsive and take up available space */
}

h2 {
    text-align: center;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    margin-bottom: 10px;
}

label {
    cursor: pointer;
}

input[type="checkbox"] {
    margin-right: 10px;
}

.card-footer_c {
    display: flex;
    justify-content: space-between;
    margin-top: 20px; /* Space between list and buttons */
}

.footer-button-refresh {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #007BFF;
    color: white;
    cursor: pointer;
    font-weight: bold;
}

.footer-button-add {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #28a745;
    color: white;
    cursor: pointer;
    font-weight: bold;
}

.footer-button-refresh:hover {
    background-color: #0056b3;
}



.footer-button-add:hover {
    background-color: #218838;
}
.form-group {
    margin-bottom: 20px; /* Increased margin to create more space between form elements */
    text-align: left;
}

label {
    display: block;
    margin-bottom: 8px; /* Increased margin between label and input field */
    font-weight: bold;
}

input[type="text"],
input[type="password"] {
    width: 100%;
    padding: 12px; /* Increased padding inside input fields */
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

input[type="text"]:focus,
input[type="password"]:focus {
    border-color: #007BFF;
    outline: none;
}

.submit-button {
    width: 100%;
    padding: 12px; /* Increased padding inside the button */
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
}

.submit-button:hover {
    background-color: #218838;
}

.inactive {
    opacity: 0.5; /* Makes it look inactive */
    pointer-events: none; /* Disables all interactions */
}



.next-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #28a745; /* Green background color */
    color: white;
    cursor: pointer;
    font-weight: bold;
}

.next-button:hover {
    background-color: #218838; /* Darker green for hover effect */
}
.main-content {
    padding-top: 13vh
}
</style>