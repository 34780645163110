<template>
 <h2>Placeholder</h2>
</template>

<script>
export default {
    name: "PlaceholderComponent"
}
</script>

<style scoped>

</style>