<template>
    <div class="loading-overlay_c">
        <div class="spinner_c"></div>
    </div>
</template>

<script>
export default {
    name: "LoadingComponent"
}
</script>

<style scoped>
.loading-overlay_c {
    position: fixed; /* Fixed position so it stays over the whole screen */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* High z-index to ensure it's on top */
}

.spinner_c {
    border: 16px solid #f3f3f3; /* Light gray background */
    border-top: 16px solid #3498db; /* Blue top border */
    border-radius: 50%; /* Make it round */
    width: 120px;
    height: 120px;
    animation: spin_c 2s linear infinite; /* Infinite spinning animation */
}

/* Spin animation */
@keyframes spin_c {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>