<template>
    <NavbarComponent is-logged-in="false"/>
    <div class="card-container">
        <div class="card">
            <h2>ERA eFactura</h2>
            <form @submit.prevent="handleSubmit">
                <div class="form-group">
                    <label for="username">Nume de utilizator</label>
                    <input type="text" id="username" v-model="username" placeholder="Introduceți numele de utilizator" />
                </div>
                <div class="form-group">
                    <label for="password">Parolă</label>
                    <input type="password" id="password" v-model="password" placeholder="Introduceți parola" />
                </div>
                <div class="form-group">
                    <span v-if="error" style="color: red">Nume de utilizator și/sau parolă greșite!</span>
                </div>
                <button type="submit" class="submit-button" :class="{ inactive: isNullOrWhitespace(username) || isNullOrWhitespace(password) || isLoading}">
                    <i class="dx-icon-lock"></i>
                    Autentificare
                </button>
            </form>
        </div>
    </div>
</template>

<script>
import config from "../configuration";

import axios from "axios";
import NavbarComponent from "@/components/NavbarComponent";

export default {
    name: "LoginComponent",
    components: {NavbarComponent},
    mounted() {
        localStorage.removeItem("Token");
        localStorage.removeItem("Firm"); //firma selectata
        localStorage.removeItem("Cert"); //certificatul ANAF
    },
    data() {
        return {
            username: "",
            password: "",
            error: false,
            isLoading: false
        }
    },
    methods: {
        isNullOrWhitespace(input) {
            return !input || !input.trim();
        },
        async handleSubmit() {
            try {
                this.isLoading = true;
                let response = await axios.post(`${config.API_URL}/Authentication/Login`,
                    {
                        Username: this.username,
                        Password: this.password
                    });
                localStorage.setItem("Token", response.data.token);
                this.$router.push("/SelectTokenAndFirm");
            }
            catch (error) {
                console.log(error);
                this.error = true;
            } finally {
                this.isLoading = false;
            }

        }
    }
}
</script>

<style scoped>
.card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.card {
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    padding: 30px; /* Increased padding to create more space inside the card */
    width: 400px;
    text-align: center;
}

.form-group {
    margin-bottom: 20px; /* Increased margin to create more space between form elements */
    text-align: left;
}

label {
    display: block;
    margin-bottom: 8px; /* Increased margin between label and input field */
    font-weight: bold;
}

input[type="text"],
input[type="password"] {
    width: 100%;
    padding: 12px; /* Increased padding inside input fields */
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

input[type="text"]:focus,
input[type="password"]:focus {
    border-color: #007BFF;
    outline: none;
}

.submit-button {
    width: 100%;
    padding: 12px; /* Increased padding inside the button */
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
}

.submit-button:hover {
    background-color: #0056b3;
}
.inactive {
    opacity: 0.5; /* Makes it look inactive */
    pointer-events: none; /* Disables all interactions */
}
</style>